import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppTheme, ThemeService } from '@services/theme/theme.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'pd-paidiem-logo',
  standalone: true,
  imports: [CommonModule],
  styles: [':host { display: block; }'],
  template: `
    <!-- {{ letterLogo }} -->
    <img *ngIf="!letterLogo" width="100%" [class.mb-2]="showSlogan" [src]="imageUrl" alt="Paidiem Logo" />
    <img
      *ngIf="letterLogo"
      style="min-width: 30px; min-height: 30px;"
      src="assets/paidiem_logo.svg"
      alt="Paidiem Logo" />
    <img *ngIf="showSlogan && !letterLogo" width="40%" [src]="sloganImageUrl" alt="Seize the pay" />
  `,
})
export class PaidiemLogoComponent implements OnDestroy, OnInit {
  @Input()
  public showSlogan: boolean = true;

  /**
   * Will hide the full PAIDIEM logo and use the
   * single letter logo instead.
   */
  @Input()
  public letterLogo: boolean = false;

  public imageUrl: string;
  public sloganImageUrl: string;
  public currentTheme$: Observable<AppTheme>;
  private themeSub: Subscription;
  public constructor(private themeService: ThemeService) {}

  public ngOnInit(): void {
    this.themeSub = this.themeService.currentTheme$.subscribe((currentTheme) => {
      this.imageUrl = this.getImageUrl(currentTheme);
      this.sloganImageUrl = this.getSloganImageUrl(currentTheme);
    });
  }

  public ngOnDestroy(): void {
    this.themeSub?.unsubscribe();
  }

  private getImageUrl(theme: AppTheme): string {
    if (theme.isDarkTheme()) {
      return 'assets/paidiem_logo_full_white.svg';
    } else {
      return 'assets/paidiem_logo_full.svg';
    }
  }

  private getSloganImageUrl(theme: AppTheme): string {
    if (theme.isDarkTheme()) {
      return 'assets/paidiem_slogan_white.svg';
    } else {
      return 'assets/paidiem_slogan.svg';
    }
  }
}
